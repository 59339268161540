import React from 'react'
import { Link } from 'react-router-dom'

function CheckList(props) {
    return (
        <>
            { props.extra ?
                <a href={props.extra} target="_blank" style={{ textDecoration: "none", }}>
                    <div style={{
                        background: "#F9F8F9",
                        borderRadius: "10px",
                        padding: 20,
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "5px 0"
                    }}>
                        {props.type ==='link' ?
                        <img src={require('../img/ancr.svg')} alt="" style={{ marginRight: 10, alignSelf: "flex-start",width:25 }} /> :
                        <img src={require('../img/file.svg')} alt="" style={{ marginRight: 10, alignSelf: "flex-start",width:25 }} />
                    }
                        <p className="checklistMobile" style={{
                            margin: 0,
                            fontSize: "15px",
                            lineHeight: "14px",
                            display: "flex",
                            alignItems: "center",
                            color: "#693668",
                            textDecoration: "none",
                            textAlign: "left"
                        }}>{props.data}</p>
                    </div>

                </a>
                : <div style={{
                    background: "#F9F8F9",
                    borderRadius: "10px",
                    padding: 20,
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "10px 0"
                }}>
                    {/* <img src={require('../img/file.svg')} alt="" style={{ marginRight: 10, alignSelf: "flex-start",width:25 }} /> */}
                    <p  className="checklistMobile" style={{
                        margin: 0,
                        fontSize: "15px",
                        lineHeight: "14px",
                        display: "flex",
                        alignItems: "center",
                        color: "#693668",
                        textAlign: "left"
                    }}>{props.data}</p>
                </div>
            }
        </>
    )
}

export default CheckList
