import React from 'react'
import {useLocation} from 'react-router-dom'
function Tags(props) {
    const location=useLocation()
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "5px 2%",
            background: "rgba(227, 218, 227, 0.2)",
            borderRadius: "8px",
            flex: 1,
            padding: 0,
            fontFamily:"AvenirNextBold !important",
            justifyContent:"flex-end",
            height:90
        }}>
            <div>
            <div style={{ height: 10, width: 10, borderLeft: "1px solid #693668", borderTop: "1px solid #693668", transform: "rotate(45deg)" }}></div>
            </div>
            <div><span style={{ fontSize: "18px",
            lineheight: "21px",
            color: "#693668",
            fontWeight: "bold",
            fontFamily:"AvenirNextBold !important"}}>{props.number !== undefined ? props.number : 0}</span></div>
            <div style={{
                    position: "relative",
                    transform: location.pathname.includes('pdf') || location.pathname.includes('screenshot') ? "translateY(-6px)" : "translateY(0px)"
            }}><span style={{
                fontWeight: "bold",
                fontSize: "18px",
                lineheight: "21px",
                color: "#693668",
                fontFamily:"AvenirNextBold !important"
            }}>{props.type}</span></div>
        </div>
    )
}

export default Tags
