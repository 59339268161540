import React, { useEffect, useState } from 'react'
import firebase from '../config/fbConfig'
import Tags from './TagsShot'
import { useRouteMatch } from 'react-router-dom';
import { Spinner, Carousel } from 'react-bootstrap';
import ReactEcharts from 'echarts-for-react';

var interval
var valState
function ScreenShot() {
    const [user, setUser] = useState(null)
    const [toggle, setToggle] = useState(true)
    const [playerAudio, setplayerAudio] = useState(0)
    let audioToggle = null;
    let match = useRouteMatch();
    const getTechSkillsValues = () => {
        if (user && user.hasOwnProperty("tech_skills"))
            return user && user.tech_skills.map(el => ({
                name: el.value,
                max: 3
            }))
        else
            return [1, 1, 1, 1, 1]
    }
    const getTechSoftValues = () => {
        if (user && user.hasOwnProperty("personal_attr"))
            return user && user.personal_attr.map(el => ({
                name: el.value,
                max: 3
            }))
        else
            return [1, 1, 1, 1, 1]
    }
    const getTechSoftRating = () => {
        if (user && user.hasOwnProperty("personal_attr"))
            return user && user.personal_attr.map(el => el.rating)
        else
            return [1, 1, 1, 1, 1]
    }
    const getTechSkillsRating = () => {
        if (user && user.hasOwnProperty("tech_skills"))
            return user && user.tech_skills.map(el => el.rating)
        else
            return [1, 1, 1, 1, 1]
    }
    const hardSkillsOptions = {
        radar: {
            name: {
                textStyle: {
                    color: '#693668',
                    borderRadius: 3,
                    padding: [0, 0],
                    fontFamily: 'AvenirNextRegular',
                    fontWeight: window.innerWidth < 768 ? 'light' : 'bold',
                    fontSize: window.innerWidth < 768 ? 7 : 14,
                    lineHeight: 18
                },
                formatter: function (value, indicator) {
                    let w = 20;
                    let t = value.split(' ');
                    let l = 0;
                    let o = '';
                    for (var tw of t) {
                        if (l + tw.length >= w) {
                            o += (l ? '\n' : '') + tw + ' '; l = tw.length + 1;
                        }
                        else {
                            o += tw + ' '; l += tw.length + 1;
                        }
                    }
                    return o;
                },
            },
            radius: 90,
            splitNumber: 3,
            splitArea: {
                areaStyle: {
                    color: ['rgba(182, 157, 182, 1)',
                        'rgba(201, 183, 201, 1)', 'rgba(225, 215, 225, 1)',
                        'rgba(114, 172, 209, 0.8)']
                }
            },
            indicator: getTechSkillsValues() || [],
            axisLine: {
                lineStyle: {
                    color: 'rgba(255, 255, 255, 1)',
                    width: 1
                }
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255, 255, 255, 1)',
                    width: 0
                }
            }
        },
        series: [{
            type: 'radar',
            data: [
                {
                    value: getTechSkillsRating() || []
                }
            ],
            color: '#693668',
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
                width: 1
            }
        }]
    }
    const softSkillsOptions = {
        radar: {
            name: {
                textStyle: {
                    color: '#693668',
                    borderRadius: 3,
                    padding: [0, 0],
                    fontFamily: 'AvenirNextRegular',
                    fontWeight: window.innerWidth < 768 ? 'light' : 'bold',
                    fontSize: window.innerWidth < 768 ? 7 : 14,
                    lineHeight: 18
                },
                formatter: function (value, indicator) {
                    console.log(value)
                    let w = 20;
                    let t = value.split(' ');
                    let l = 0;
                    let o = '';
                    for (var tw of t) {
                        if (l + tw.length >= w) {
                            o += (l ? '\n' : '') + tw + ' '; l = tw.length + 1;
                        }
                        else {
                            o += tw + ' '; l += tw.length + 1;
                        }
                    }
                    return o;
                }
            },
            radius: 90,
            splitNumber: 3,
            splitArea: {
                areaStyle: {
                    color: ['rgba(182, 157, 182, 1)',
                        'rgba(201, 183, 201, 1)', 'rgba(225, 215, 225, 1)',
                        'rgba(114, 172, 209, 0.8)']
                }
            },
            indicator: getTechSoftValues() || [],
            axisLine: {
                lineStyle: {
                    color: 'rgba(255, 255, 255, 1)',
                    width: 1
                }
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(255, 255, 255, 1)',
                    width: 0
                }
            }
        },
        series: [{
            type: 'radar',
            data: [
                {
                    value: getTechSoftRating() || []
                }
            ],
            color: '#693668',
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
                width: 1
            }
        }]
    }

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://europe-west1-jokko-app.cloudfunctions.net/publicProfile/?uid=" + match.params.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setUser(result.user)
            })
            .catch(error => console.log('error', error));
    }, [])

    useEffect(() => {
        valState = playerAudio
        console.log(valState)
    }, [playerAudio])


    return (
        <>
            {
                user &&
                <div style={{ height: 1080, width: 1080, overflow: "hidden", margin: 'auto', display: "flex", flexDirection: "column", justifyContent: "space-betwen" }}>
                    <div className="container shot" style={{ width: "100%", maxWidth: "100%", paddingLeft: 0, paddingRight: 0 }}>
                        <h1 className="shotTitle" style={{
                            marginTop: 35,
                            fontSize: 53,
                            lineHeight: "22px",
                            textAlign: "center",
                            color: "#693668",
                            fontWeight: "lighter",
                        }} >{user.firstName} {user.lastName}</h1>
                        <br />
                        {user.experience &&
                            <div className="experience shot">
                                <span>
                                    {user.experience} years experience
                                </span>
                            </div>
                        }
                        <br />
                        <p className="bioshot" style={{
                            fontSize: 22,
                            display: "flex",
                            justifyContent: "center",
                            color: "#693668",
                            // fontWeight: "bold",
                            marginBottom: 30
                        }}>{user.bio}</p>
                        <div className="mobile" style={{ border: "1px solid #EBEBEB", height: "2px", margin: "0 20px" }}></div>

                        <div style={{ border: "1px solid #EBEBEB", height: "2px", margin: "0 20px" }}></div>
                        {user.domains &&
                            <div className="flowingTags shot" style={{ display: "flex", marginTop: "5px !important", marginBottom: "5px !important", flexWrap: "wrap" }}>
                                {
                                    user.domains.map(el => <Tags key={el.id} number={user.hasOwnProperty("upvotes") ? user.upvotes[el.id] : 0} type={el.value} />)
                                }

                            </div>
                        }
                        {user.tech_skills && user.tech_skills.length > 0 &&<>
                        <div style={{ border: "1px solid #EBEBEB", height: "2px", margin: "0 20px" }}></div>
                        <br />
                            <div className="charts desktop" style={{ marginTop: "15px", transform: "translateY(20px)" }}>
                                <div style={{ flex: 1 }}>
                                    <h2 style={{ fontSize: 35, marginBottom: 25 }}>Hard Skills</h2>
                                    <ReactEcharts
                                        option={hardSkillsOptions}
                                        style={{ height: '345px', transform: "translateY(-30px)" }}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <h2 style={{ fontSize: 35, marginBottom: 25 }}>Soft Skills</h2>
                                    <ReactEcharts
                                        option={softSkillsOptions}
                                        style={{ height: '345px', transform: "translateY(-30px)" }}
                                    />
                                </div>
                            </div>
                        <div style={{ border: "1px solid rgb(235, 235, 235)", height: "2px", margin: "0px 20px" }} />

                        <br /></>
                        }
                        <div style={{ marginTop: "15px" }}>
                            <h2 style={{ fontSize: 35 }}>Interested in</h2>
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-around"
                            }}>
                                {user.interests && user.interests.length >0 &&  user.interests.map(el => <div style={{ textAlign: "center", flex: 1, }} key={el.id}>
                                    <img src={el.icon} alt="" style={{ width: 135, margin: "0 10px", }} />
                                    <h4 style={{
                                        fontSize: "18px",
                                        lineHeight: "21px",
                                        textAlign: "center",
                                        color: "#693668",
                                        fontWeight: "lighter"
                                    }}>{el.text}</h4>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <br />
                    <footer style={{
                        background: "#693668",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "8px 0px",
                        boxShadow: "3px 5px 35px rgba(0, 0, 0, 0.5)"
                    }}>
                        <p style={{ fontWeight: "bold", fontFamily: "AvenirNext", fontSize: "14px", lineHeight: "14px", marginBottom: "3px" }}>CHECK MY PROFILE</p>
                        <div className="printShowFlex" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={require('../img/footerLogo.svg')} style={{ width: "150px" }} />
                        </div>
                    </footer>
                </div>
            }
        </>
    )
}

export default ScreenShot
