import firebase from 'firebase/app';
import 'firebase/database';

const config = {
    apiKey: "AIzaSyCNQspIL2RmVn1wtrDZcqwNyVvyXSTIINc",
    authDomain: "jokko-app.firebaseapp.com",
    databaseURL: "https://jokko-app.firebaseio.com",
    projectId: "jokko-app",
    storageBucket: "jokko-app.appspot.com",
    messagingSenderId: "140935010903",
    appId: "1:140935010903:web:6cc48b36116be536e16112",
    measurementId: "G-EHE99DD25X"
};
firebase.initializeApp(config);
const fb = firebase.database()
export default fb 