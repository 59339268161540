import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from './NotFound';
import ScreenShot from './components/ScreenShot';
import Pdf from './components/Pdf';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/:id" component={App} exact />
        <Route path="/screenshot/:id" component={ScreenShot} exact />
        <Route path="/pdf/:id" component={Pdf} exact />
        <Route component={NotFound} exact />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
